import * as React from 'react';

const ConformityIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width={24}
        height={24}
        fill='none'
        viewBox='0 0 24 24'
        {...props}
    >
        <path
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth={2}
            d='m7 12 5 5L22 7M2 12l5 5m5-5 5-5'
        />
    </svg>
);
export default ConformityIcon;
