import { InvalidateQueryFilters, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

const useJiminiQuery = () => {
    const queryClient = useQueryClient();

    const query = useCallback(
        async (
            queryKey: string[],
            filters: InvalidateQueryFilters,
            cancelRefetch: boolean
        ) => {
            await queryClient.invalidateQueries(
                {
                    queryKey: [...queryKey],
                    refetchType: 'all',
                    exact: false,
                    ...filters,
                },
                {
                    cancelRefetch,
                }
            );
        },
        []
    );

    const refetchLibraries = async () => {
        // this is the data in the library/documents page
        await query(['allLibraries'], {}, true);
    };

    const refetchLibraryByPath = async (path: string) => {
        // no cancelling to avoid some other refetch logic to be cancelled
        // such as embedding status
        await query(
            [path, 'allLibraries'],
            { exact: true, refetchType: 'active' },
            false
        );
    };
    const refetchFolderList = async (library: string) => {
        // this is the data for the move document modal (destination folder selection)
        await query(
            [`library-folders-${library}`],
            { exact: true, refetchType: 'active' },
            false
        );
    };

    const refetchMissionTracking = async () => {
        // this will be both the request handling conversations in sidebar and in the conversation list page
        await query(
            ['missionTracker'],
            { exact: true, refetchType: 'all' },
            true
        );
    };

    const refetchConversations = async () => {
        // this will be both the request handling conversations in sidebar and in the conversation list page
        await query(
            ['conversationsList'],
            { exact: false, refetchType: 'all' },
            true
        );
        refetchMissionTracking();
    };

    const refetchConversationById = async (id: string) => {
        // this is for the conversation page
        await query(
            ['conversation', id],
            { exact: true, refetchType: 'active' },
            false
        );
    };

    const refetchMessageById = async (id: string) => {
        // this is for a single message, used in the conversation page
        await query(
            ['message', id],
            { exact: true, refetchType: 'active' },
            false
        );
    };

    const refetchDataroom = async () => {
        await query(['dataroom'], { exact: false, refetchType: 'all' }, true);
    };

    return {
        refetchLibraries,
        refetchLibraryByPath,
        refetchFolderList,
        refetchConversations,
        refetchConversationById,
        refetchMessageById,
        refetchMissionTracking,
        refetchDataroom,
    };
};

export default useJiminiQuery;
