import React from 'react';

const DOCIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width={17}
        height={21}
        viewBox='0 0 17 21'
        fill='none'
        {...props}
    >
        <path
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth={2}
            d='M10 1.5v4a1 1 0 0 0 1 1h4'
        />
        <path
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth={2}
            d='M1 10.5v-7a2 2 0 0 1 2-2h7l5 5v4m1 4.5a1.5 1.5 0 1 0-3 0v3a1.5 1.5 0 1 0 3 0M1 13.5v6h1a2 2 0 0 0 2-2v-2a2 2 0 0 0-2-2H1Zm7.5 0A1.5 1.5 0 0 1 10 15v3a1.5 1.5 0 0 1-3 0v-3a1.5 1.5 0 0 1 1.5-1.5Z'
        />
    </svg>
);
export default DOCIcon;
