import React from 'react';

const PDFIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width={17}
        height={21}
        viewBox='0 0 17 21'
        fill='none'
        {...props}
    >
        <path
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth={2}
            d='M10 1.5v4a1 1 0 0 0 1 1h4'
        />
        <path
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth={2}
            d='M1 10.5v-7a2 2 0 0 1 2-2h7l5 5v4m-14 6h1.5a1.5 1.5 0 1 0 0-3H1v6m12-3h2m1-3h-3v6m-6-6v6h1a2 2 0 0 0 2-2v-2a2 2 0 0 0-2-2H7Z'
        />
    </svg>
);
export default PDFIcon;
