import React from 'react';

const TranslationIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width={20}
        height={20}
        viewBox='0 0 24 24'
        fill='none'
        {...props}
    >
        <path
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth={2}
            d='M4 5h7M9 3v2c0 4.418-2.239 8-5 8m1-4c0 2.144 2.952 3.908 6.7 4m.3 7 4-9 4 9m-.9-2h-6.2'
        />
    </svg>
);
export default TranslationIcon;
