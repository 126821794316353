import { FC, SVGProps } from 'react';
import NumberedListIcon from '@/components/Icons/NumberedListIcon';
import MagnifyingGlassReticula from '@/components/Icons/MagnifyingGlassReticula';
import TranslationIcon from '@/components/Icons/TranslationIcon';
import SpeechBubble from '@/components/Icons/SpeechBubble';
import { AllMissions, MissionsEnum } from '@/types/analyzer';
import GridIcon from '@/components/Icons/GridIcon';
import MagicWandIcon from '@/components/Icons/MagicWandIcon';
import TimelineIcon from '@/components/Icons/TimelineIcon';
import ConformityIcon from '@/components/Icons/ConformityIcon';
import ClausesIcon from '@/components/Icons/ClausesIcon';
import SearchADBIcon from '@/components/Icons/SearchADBIcon';
import AnonymizationIcon from '@/components/Icons/AnonymizationIcon';

export type UpcommingMissions = 'timeline' | 'conformity' | 'clauses';

export type MissionSpecificGridModal = {
    titleModalInfo: string;
    modalTopDescription?: string;
    modalSteps?: string[];
    modalAdvice?: string;
    modalAdviceExamples?: string[];
};

export type MissionSpecificObject = {
    Icon: FC<SVGProps<SVGSVGElement>>;
    title: string;
    gridModal?: MissionSpecificGridModal;
    subTitleMissionGrid: string;
    titleMissionGrid: string;
    titleFollowUp: string;
    titlePromptHeader: string;
    titleThreadPage: string;
    titleMissionTracking: string;
    titleDataroomWarning: string;
    titleAnswerDrawer: string;
    missionAvailable: boolean;
    missionInGrid: boolean;
    hasDataRoom: boolean;
};

export type MissionSpecificMappingType = {
    [key in AllMissions]: MissionSpecificObject;
};

const missionSpecificUiMapping = {
    factual: {
        Icon: MagnifyingGlassReticula,
        title: 'Analyse de documents',
        gridModal: {
            titleModalInfo: 'Analyse de documents',
            modalTopDescription:
                'Posez une question à Jimini sur un ou plusieurs documents pour obtenir des réponses précises sourcées.',
            modalSteps: [
                'Sélectionnez les documents à analyser.',
                'Posez votre question à Jimini AI.',
                'Jimini AI fournit une réponse sourcée.',
            ],
            modalAdvice: 'Posez des questions directes et précises. Exemples :',
            modalAdviceExamples: [
                '"Le contrat contient-il une clause de non-concurrence ?"',
                '"Quelles sont les obligations de confidentialité des parties ?"',
                '"Quelle est la durée du contrat ?"',
            ],
        },
        titleMissionGrid: 'Analyse de documents',
        subTitleMissionGrid: 'juridique et sourcée',
        titleFollowUp: 'Analyse de documents',
        titlePromptHeader: 'Analyse de documents',
        titleThreadPage: 'Analyse de documents',
        titleMissionTracking: 'Analyse de documents',
        titleDataroomWarning: '',
        titleAnswerDrawer: '',
    },
    summarization: {
        Icon: NumberedListIcon,
        title: 'Synthèse juridique',
        gridModal: {
            titleModalInfo: 'Synthèse juridique',
            modalTopDescription:
                'Sélectionnez un document pour générer une synthèse. Listez vos thèmes d’intérêt pour que Jimini AI se concentre sur des points spécifiques.',
            modalSteps: [
                'Sélectionnez le document à synthétiser.',
                'Ajoutez vos thèmes d’intérêt, si vous le souhaitez.',
                'Jimini AI génère la synthèse.',
            ],
            modalAdvice:
                'Pour des synthèses plus pertinentes vos besoins, définissez des thèmes d’intérêt précis (exemple : “Motifs de licenciement”).',
        },
        titleMissionGrid: 'Synthèse juridique',
        subTitleMissionGrid: "intelligente d'un document",
        titleFollowUp: 'Synthèse juridique',
        titlePromptHeader: 'Synthèse juridique',
        titleThreadPage: 'Synthèse juridique',
        titleMissionTracking: 'Synthèse juridique',
        titleDataroomWarning: '',
        titleAnswerDrawer: 'Synthèse juridique',
    },
    translation: {
        Icon: TranslationIcon,
        title: 'Traduction juridique',
        gridModal: {
            titleModalInfo: 'Traduction juridique',
            modalTopDescription:
                'Sélectionnez un document ou entrez un texte pour obtenir une traduction juridique, alimentée par les derniers glossaires juridiques. Choisissez la langue cible, Jimini AI détecte automatiquement la langue source du document.',
            modalSteps: [
                'Sélectionnez un document ou entrez un texte à traduire.',
                'Choisissez la langue cible pour la traduction.',
                'Jimini AI fournit la traduction.',
            ],
        },
        titleMissionGrid: 'Traduction juridique',
        subTitleMissionGrid: "d'un texte ou d'un document",
        titleFollowUp: 'Traduction juridique',
        titlePromptHeader: 'Traduction juridique',
        titleThreadPage: 'Traduction juridique',
        titleMissionTracking: 'Traduction juridique',
        titleDataroomWarning: 'traduction juridique de texte',
        titleAnswerDrawer: '',
    },
    document_translation: {
        Icon: TranslationIcon,
        title: 'Traduction juridique',
        gridModal: {
            titleModalInfo: 'Traduction juridique',
            modalTopDescription:
                'Sélectionnez un document ou entrez un texte pour obtenir une traduction précise. Choisissez la langue cible, Jimini AI détecte automatiquement la langue source du document.',
            modalSteps: [
                'Sélectionnez un document ou entrez un texte à traduire.',
                'Choisissez la langue cible pour la traduction.',
                'Jimini AI fournit la traduction.',
            ],
        },
        titleMissionGrid: 'Traduction juridique',
        subTitleMissionGrid: "d'un texte ou d'un document",
        titleFollowUp: 'Traduction juridique',
        titlePromptHeader: 'Traduction juridique',
        titleThreadPage: 'Traduction juridique',
        titleMissionTracking: 'Traduction juridique',
        titleDataroomWarning: 'traduction juridique de texte',
        titleAnswerDrawer: '',
    },
    matrice: {
        Icon: GridIcon,
        title: "Matrice d'analyse",
        gridModal: {
            titleModalInfo: "Matrice d'analyse",
            modalTopDescription:
                "Optimisez vos audits juridiques : Jimini analyse les documents téléchargés et extrait automatiquement les réponses pertinentes à vos questions, pour chaque document. Les résultats sont organisés dans un tableau à double entrée, facilement exportable en formats Excel ou Word, vous permettant ainsi d'accélérer et de structurer votre analyse.",
            modalSteps: [
                'Sélectionnez les documents à analyser (Word ou PDF).',
                'Définissez votre jeu de questions.',
                'Jimini AI extrait les réponses et les présente dans un tableau exportable.',
            ],
            modalAdvice:
                'Sélectionnez le format de réponse souhaité pour aider Jimini AI à répondre.\n\nPosez des questions directes et précises. Exemples :',
            modalAdviceExamples: [
                '“Le contrat contient-il une clause de non-concurrence ?”',
                '“Quelles sont les obligations de confidentialité des parties ?”',
                '“Quelle est la durée du contrat ?”',
            ],
        },
        titleMissionGrid: "Matrice d'analyse",
        subTitleMissionGrid: 'multi-documents',
        titleFollowUp: "Matrice d'analyse",
        titlePromptHeader: "Matrice d'analyse",
        titleThreadPage: "Matrice d'analyse",
        titleMissionTracking: "Matrice d'analyse",
        titleDataroomWarning: '',
        titleAnswerDrawer: "Matrice d'analyse",
    },
    magic_wand: {
        Icon: MagicWandIcon,
        title: 'Baguette magique',
        gridModal: {
            titleModalInfo: 'Baguette magique',
            modalTopDescription:
                'Corrigez vos document Word® en format "marque de révision". Jimini AI vérifie et harmonise le contenu : correction du texte et de la syntaxe, mise en forme et vérification juridique.',
            modalSteps: [
                'Sélectionnez le document à corriger.',
                'Choisissez les vérifications à opérer.',
                'Jimini AI applique les modifications au document.',
            ],
        },
        titleMissionGrid: 'Baguette magique',
        subTitleMissionGrid: 'Corrections par IA',
        titleFollowUp: 'Baguette magique',
        titlePromptHeader: 'Baguette magique',
        titleThreadPage: 'Baguette magique',
        titleMissionTracking: 'Baguette magique',
        titleDataroomWarning: 'baguette magique',
        titleAnswerDrawer: 'Baguette magique',
    },
    search_adb: {
        Icon: SearchADBIcon,
        title: 'Recherche dans une base de donnée',
        gridModal: {
            titleModalInfo: 'Recherche dans une base de donnée',
            modalTopDescription:
                'Retrouvez un document dans votre base de données.',
            modalSteps: [
                'Sélectionnez la base de données dans laquelle effectuer votre recherche.',
                'Posez votre question en langage naturel.',
                'Jimini liste les documents les plus pertinents retrouvés dans votre base de données.',
            ],
            modalAdvice: 'Soyez précis dans vos instructions. Exemples :',
            modalAdviceExamples: [
                '"Trouve un pacte d\'actionnaire contenant une clause de tag along pour une société dans l’Intelligence Artificielle, après 2023"',
                '"Cherche un exemple de protocole transactionnel conclu dans le cas d’une contestation de licenciement par un salarié"',
            ],
        },
        titleMissionGrid: 'Recherche',
        subTitleMissionGrid: 'dans une base de donnée',
        titleFollowUp: 'Recherche dans une base de donnée',
        titlePromptHeader: 'Recherche dans une base de donnée',
        titleThreadPage: 'Recherche dans une base de donnée',
        titleMissionTracking: 'Recherche dans une base de donnée',
        titleDataroomWarning: '',
        titleAnswerDrawer: 'Recherche à partir d’une base de données',
    },
    anonymization: {
        Icon: AnonymizationIcon,
        title: 'Anonymisation',
        gridModal: {
            titleModalInfo: 'Anonymisation',
            modalTopDescription:
                'Anonymisez un document juridique selon des paramètres sélectionnés.',
            modalSteps: [
                'Uploadez votre document (PDF, Word ou document scanné).',
                'Sélectionnez les paramètres à anonymiser.',
                'Téléchargez votre document anonymisé.',
            ],
        },
        titleMissionGrid: 'Anonymisation',
        subTitleMissionGrid: "d'un document",
        titleFollowUp: 'Anonymisation',
        titlePromptHeader: 'Anonymisation',
        titleThreadPage: 'Anonymisation',
        titleMissionTracking: 'Anonymisation',
        titleDataroomWarning: 'anonymisation',
        titleAnswerDrawer: '',
    },
    timeline: {
        Icon: TimelineIcon,
        title: "Chronologie d'évènements",
        titleModalInfo: "Chronologie d'évènements",
        titleMissionGrid: "Chronologie d'évènements",
        subTitleMissionGrid: 'à partir de documents',
        titleFollowUp: "Chronologie d'évènements",
        titlePromptHeader: "Chronologie d'évènements",
        titleThreadPage: "Chronologie d'évènements",
        titleMissionTracking: "Chronologie d'évènements",
        titleDataroomWarning: '',
        titleAnswerDrawer: '',
    },
    conformity: {
        Icon: ConformityIcon,
        title: 'Vérification de conformité',
        titleModalInfo: 'Vérification de conformité',
        titleMissionGrid: 'Vérification de conformité',
        subTitleMissionGrid: "d'un contrat ou document",
        titleFollowUp: 'Vérification de conformité',
        titlePromptHeader: 'Vérification de conformité',
        titleThreadPage: 'Vérification de conformité',
        titleMissionTracking: 'Vérification de conformité',
        titleDataroomWarning: '',
        titleAnswerDrawer: '',
    },
    clauses: {
        Icon: ClausesIcon,
        title: 'Analyse de clauses',
        titleModalInfo: 'Analyse de clauses',
        modalTopDescription:
            'Posez une question à Jimini sur un ou plusieurs documents pour obtenir des réponses précises sourcées.',
        modalSteps: [
            'Sélectionnez les documents à analyser.',
            'Posez votre question à Jimini AI.',
            'Jimini AI fournit une réponse sourcée.',
        ],
        modalAdvice: 'Posez des questions directes et précises. Exemples :',
        modalAdviceExamples: [
            'Le contrat contient-il une clause de non-concurrence ?',
            'Quelles sont les obligations de confidentialité des parties ?',
            'Quelle est la durée du contrat ?',
        ],
        titleMissionGrid: 'Analyse de clauses',
        subTitleMissionGrid: "d'un document juridique",
        titleFollowUp: 'Analyse de clauses',
        titlePromptHeader: 'Analyse de clauses',
        titleThreadPage: 'Analyse de clauses',
        titleMissionTracking: 'Analyse de clauses',
        titleDataroomWarning: '',
        titleAnswerDrawer: '',
    },
    generic: {
        Icon: SpeechBubble,
        title: 'Requête libre',
        titleModalInfo: 'Requête libre',
        modalTopDescription:
            'Posez une question à Jimini sur un ou plusieurs documents pour obtenir des réponses précises sourcées.',
        modalSteps: [
            'Sélectionnez les documents à analyser.',
            'Posez votre question à Jimini AI.',
            'Jimini AI fournit une réponse sourcée.',
        ],
        modalAdvice: 'Posez des questions directes et précises. Exemples :',
        modalAdviceExamples: [
            'Le contrat contient-il une clause de non-concurrence ?',
            'Quelles sont les obligations de confidentialité des parties ?',
            'Quelle est la durée du contrat ?',
        ],
        titleMissionGrid: 'Requête libre',
        subTitleMissionGrid: 'Requête libre',
        titleFollowUp: 'Requête libre',
        titlePromptHeader: 'Requête libre',
        titleThreadPage: 'Question générique',
        titleMissionTracking: 'Requête diverse',
        titleDataroomWarning: 'requête libre',
        titleAnswerDrawer: '',
    },
    default: {
        Icon: SpeechBubble,
        title: 'Suivi de mission',
        titleModalInfo: 'Suivi de mission',
        modalTopDescription:
            'Posez une question à Jimini sur un ou plusieurs documents pour obtenir des réponses précises sourcées.',
        modalSteps: [
            'Sélectionnez les documents à analyser.',
            'Posez votre question à Jimini AI.',
            'Jimini AI fournit une réponse sourcée.',
        ],
        modalAdvice: 'Posez des questions directes et précises. Exemples :',
        modalAdviceExamples: [
            'Le contrat contient-il une clause de non-concurrence ?',
            'Quelles sont les obligations de confidentialité des parties ?',
            'Quelle est la durée du contrat ?',
        ],
        titleMissionGrid: '',
        subTitleMissionGrid: '',
        titleFollowUp: '',
        titlePromptHeader: '',
        titleThreadPage: '',
        titleMissionTracking: 'Requête diverse',
        titleDataroomWarning: 'requête libre',
        titleAnswerDrawer: '',
    },
};

const missionSpecificMapping: MissionSpecificMappingType = {
    factual: {
        ...missionSpecificUiMapping.factual,
        missionAvailable: true,
        missionInGrid: true,
        hasDataRoom: true,
    },
    summarization: {
        ...missionSpecificUiMapping.summarization,
        missionAvailable: true,
        missionInGrid: true,
        hasDataRoom: true,
    },
    translation: {
        ...missionSpecificUiMapping.translation,
        missionAvailable: true,
        missionInGrid: true,
        hasDataRoom: false,
    },
    matrice: {
        ...missionSpecificUiMapping.matrice,
        missionAvailable: true,
        missionInGrid: true,
        hasDataRoom: true,
    },
    magic_wand: {
        ...missionSpecificUiMapping.magic_wand,
        missionAvailable: true,
        missionInGrid: true,
        hasDataRoom: true,
    },
    search_adb: {
        ...missionSpecificUiMapping.search_adb,
        missionAvailable: true,
        missionInGrid: true,
        hasDataRoom: true,
    },
    anonymization: {
        ...missionSpecificUiMapping.anonymization,
        missionAvailable: true,
        missionInGrid: true,
        hasDataRoom: true,
    },
    timeline: {
        ...missionSpecificUiMapping.timeline,
        missionAvailable: false,
        missionInGrid: true,
        hasDataRoom: true,
    },
    conformity: {
        ...missionSpecificUiMapping.conformity,
        missionAvailable: false,
        missionInGrid: true,
        hasDataRoom: true,
    },
    clauses: {
        ...missionSpecificUiMapping.clauses,
        missionAvailable: false,
        missionInGrid: false,
        hasDataRoom: true,
    },
    generic: {
        ...missionSpecificUiMapping.generic,
        missionAvailable: true,
        missionInGrid: false,
        hasDataRoom: false,
    },
    default: {
        ...missionSpecificUiMapping.default,
        missionAvailable: false,
        missionInGrid: false,
        hasDataRoom: false,
    },
};
const MAX_MATRICE_DOCS = 10;
const MAX_SUMMARIZATION_DOCS = 1; // Don't forget to add an 's'to 'document' below if more than 1
const MAX_FACTUAL_DOCS = 5;
const MAX_MAGIC_WAND_DOCS = 1;
const MAX_TRANSLATION_DOCS = 1;
const MAX_TRANSLATION_CHARS = 40000;
const MAX_ANONYMIZATION_DOCS = 1;

const searchADBTutorialConfig = {
    title: 'Formulez votre recherche en langage naturel. Jimini analysera la base de données choisie et extraira uniquement les documents pertinents.',
    containerClassName: 'mx-6  mt-2 ',
    goodQuestionsArray: [
        'Cherche un mémo sur les droits des consommateurs en matière de garantie légale de conformité, incluant les délais de prescription et les recours possibles',
        'Trouve un précédent de Share Purchase Agreement (SPA) multi Special Purpose Vehicule (SPV) portant sur un immeuble de bureaux sans condition suspensive, après 2023',
    ],
    badQuestionsArray: [
        'Contrat SPA',
        'Pactes d’actionnaires clause tag-along',
        'Conclusions incidentes pratiques commerciales trompeuses',
    ],
};
const matrixTutorialConfig = {
    title: 'Saisissez une série de questions auxquelles Jimini AI répondra pour chaque document. Posez des questions directes et précises.',
    containerClassName: 'mx-6  mt-2 ',
    goodQuestionsArray: [
        "Qui est l'auteur de ce document ?",
        'Le contrat contient-il une clause de non-concurrence ?',
        'Quelles sont les obligations de confidentialité des parties ?',
        'Quelle est la durée du contrat ?',
    ],
    badQuestionsArray: [
        'Qui sont les auteurs de ces 6 documents ?',
        'Clause de non-concurrence ?',
        'Quelles obligations dans le contrat ?',
        'Durée',
    ],
};
const factualTutorialConfig = {
    title: 'Saisissez une question à laquelle Jimini AI répondra après avoir analysé les documents sélectionnés. Posez des questions directes et précises.',
    containerClassName: 'ml-1 mt-0',
    goodQuestionsArray: [
        'Le contrat contient-il une clause de non-concurrence ?',
        'Quelles sont les obligations de confidentialité des parties ?',
        'Quelle est la durée du contrat ?',
    ],
    badQuestionsArray: [
        'Clause de non-concurrence ?',
        'Quelles obligations dans le contrat ?',
        'Durée',
    ],
};

const isDataroomMission = (selectedMission: MissionsEnum) => {
    return missionSpecificMapping[selectedMission].hasDataRoom;
};

export {
    missionSpecificUiMapping,
    missionSpecificMapping,
    MAX_MATRICE_DOCS,
    MAX_SUMMARIZATION_DOCS,
    MAX_FACTUAL_DOCS,
    MAX_MAGIC_WAND_DOCS,
    MAX_TRANSLATION_DOCS,
    MAX_TRANSLATION_CHARS,
    MAX_ANONYMIZATION_DOCS,
    matrixTutorialConfig,
    factualTutorialConfig,
    searchADBTutorialConfig,
    isDataroomMission,
};
