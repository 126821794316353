import * as React from 'react';

const AnonymizationIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width={24}
        height={24}
        viewBox='0 0 24 24'
        fill='none'
        {...props}
    >
        <path
            d='M1 19.5V17.5C1 16.4391 1.42143 15.4217 2.17157 14.6716C2.92172 13.9214 3.93913 13.5 5 13.5H8.5M14 20.5V20.51M14 17.5C14.4483 17.4986 14.8832 17.3468 15.235 17.069C15.5868 16.7911 15.8352 16.4033 15.9406 15.9675C16.0459 15.5318 16.0019 15.0733 15.8158 14.6655C15.6297 14.2576 15.3122 13.924 14.914 13.718C14.5162 13.5142 14.0611 13.451 13.6228 13.5387C13.1845 13.6264 12.7888 13.8598 12.5 14.201M3 5.5C3 6.56087 3.42143 7.57828 4.17157 8.32843C4.92172 9.07857 5.93913 9.5 7 9.5C8.06087 9.5 9.07828 9.07857 9.82843 8.32843C10.5786 7.57828 11 6.56087 11 5.5C11 4.43913 10.5786 3.42172 9.82843 2.67157C9.07828 1.92143 8.06087 1.5 7 1.5C5.93913 1.5 4.92172 1.92143 4.17157 2.67157C3.42143 3.42172 3 4.43913 3 5.5Z'
            stroke='#00132B'
            strokeOpacity='0.65098'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);
export default AnonymizationIcon;
