'use client';
import React, { useMemo } from 'react';
import DocumentNameShortened from '@/components/Chat/DocumentNameShortened';
import ThreadsPillContainer from '@/components/Conversations/ThreadsPillContainer';
import { PublicLibraryFile } from '@/types/requests';

const ThreadsFileShortenedNameButton = ({
    file,
    index,
    openDocument,
}: {
    file: PublicLibraryFile;
    index: number;
    openDocument: (file: PublicLibraryFile) => void;
}) => {
    return (
        <div
            className='cursor-pointer hover:underline'
            onClick={() => openDocument(file)}
        >
            <DocumentNameShortened
                type={file.type}
                name={file.name}
                iconClassname='stroke-white'
                iconContainerClassname='mr-0'
                textClassName={
                    'font-gilroySemiBold text-white text-sm h-[16px]'
                }
                className={`w-fit p-0 ${index === 0 ? '' : 'mt-2'}`}
                iconProps={{
                    width: 16,
                    height: 16,
                    viewBox: '0 0 22 22',
                }}
                maxWidthClassName='max-w-[168px]'
            />
        </div>
    );
};

const ThreadsFirstFileShortenedNameButton = ({
    files,
    openDocument,
    ...props
}: {
    files: PublicLibraryFile[];
    openDocument: (file: PublicLibraryFile) => void;
}) => {
    const firstDoc = files[0];

    const suffix = useMemo(() => {
        const filesInTooltip = files.slice(1, files.length);
        if (!filesInTooltip?.length) {
            return '';
        } else {
            return ` + ${filesInTooltip.length} ${filesInTooltip.length === 1 ? 'document' : 'documents'}`;
        }
    }, [files]);
    return (
        <ThreadsPillContainer onClick={() => openDocument(firstDoc)}>
            <DocumentNameShortened
                type={firstDoc.type}
                name={firstDoc.name}
                iconContainerClassname='mr-0'
                iconProps={{
                    width: 16,
                    height: 16,
                    viewBox: '0 0 22 22',
                }}
                className='p-0'
                iconClassname='stroke-solidGray11'
                textClassName={
                    'font-gilroySemiBold text-solidGray11 text-sm h-[16px]'
                }
                extensionColorClassName='text-solidGray11'
                maxWidthClassName='desktop:max-w-[290px]  laptop:max-w-[240px] tablet:max-w-[100px] mobile:max-w-[60px]'
                suffix={
                    <span className='ml-1 h-[16px] whitespace-nowrap font-gilroySemiBold text-sm text-solidGray9'>
                        {suffix}
                    </span>
                }
            />
        </ThreadsPillContainer>
    );
};

export { ThreadsFileShortenedNameButton, ThreadsFirstFileShortenedNameButton };
