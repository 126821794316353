import { FileSystemType } from '@/types/documentPicker';
import { PublicLibraryFile } from '@/types/requests';
import { FrontendLibraryFilesType } from '@/types/analyzer';

type SearchFileIntermediaryType = FrontendLibraryFilesType & {
    initial_dropdown?: boolean;
    sub_path?: SearchFileIntermediaryType[];
};
type GroupedFilesType = { [key: string]: SearchFileIntermediaryType[] };

function groupByParent(data: FrontendLibraryFilesType[]) {
    const grouped: GroupedFilesType = {};

    // First create an initial grouping by parent_file
    data.forEach((item) => {
        if (!grouped[`${item.parent_file}`]) {
            grouped[`${item.parent_file}`] = [item];
        } else {
            grouped[`${item.parent_file}`].push(item);
        }
    });

    // Recursive function if encountering a folder
    function buildHierarchy(parent: string) {
        if (!grouped[parent]) {
            return [];
        }
        return grouped[`${parent}`].length
            ? grouped[`${parent}`].map((item) => {
                  if (!item) {
                      return item;
                  }
                  const children = buildHierarchy(item.id);
                  if (children.length) {
                      item.sub_path = children;
                  }
                  return item;
              })
            : [];
    }

    return buildHierarchy('null'); // Start building hierarchy from root (where parent_file is null)
}

function searchFiles(files: FrontendLibraryFilesType[], searchString: string) {
    const searchWords = searchString.toLowerCase().split(' ');
    const matchedFiles = new Set<FrontendLibraryFilesType>();

    function addFileAndParents(fileId: string) {
        let currentFile: SearchFileIntermediaryType | null =
            files.find((file) => file.id === fileId) ?? null;
        while (currentFile) {
            currentFile.initial_dropdown = true;
            matchedFiles.add(currentFile);
            if (currentFile.parent_file !== null) {
                currentFile =
                    files.find(
                        (file) => file.id === currentFile?.parent_file
                    ) ?? null;
            } else {
                currentFile = null;
            }
        }
    }

    function addChildren(fileId: string) {
        files.forEach((file) => {
            if (file.parent_file === fileId) {
                matchedFiles.add(file);
                if (file.type === '1_folder') {
                    addChildren(file.id);
                }
            }
        });
    }

    files.forEach((file) => {
        for (const word of searchWords) {
            if (file.name.toLowerCase().includes(word)) {
                matchedFiles.add(file);
                if (file.parent_file !== null) {
                    addFileAndParents(file.parent_file);
                }
                if (file.type === '1_folder') {
                    addChildren(file.id);
                }
                break;
            }
        }
    });

    return Array.from(matchedFiles);
}

function splitFileName(fileName: string = '') {
    const extension =
        fileName.toLowerCase().match(/\.(pdf|docx|doc|odt)$/)?.[0] ?? ''; // get the extension
    const end =
        extension.length > 0
            ? fileName.slice(-4 - extension.length, -extension.length)
            : fileName.slice(-4); // get the last 4 characters before the extension and the extension
    const start = fileName.slice(0, -4 - extension.length); // get the rest of the string

    // here we are using text-ellipsis to truncate [the text part before file extension] minus 4 characters
    // La_redaction_amendements_ss-amendements_motions-de-procedure.pdf
    // becomes
    // La_redaction_amendements_ss-amendements_motions...dure.pdf
    return {
        start,
        end,
    };
}

const recursiveGetChildren = (obj: FileSystemType) => {
    const children: FileSystemType[] = [];

    // Recursive function to traverse the object
    function traverse(obj: FileSystemType) {
        if (obj.id !== undefined) {
            children.push(obj);
        }

        if (obj.sub_path && Array.isArray(obj.sub_path)) {
            obj.sub_path.forEach((item) => traverse(item));
        }
    }

    traverse(obj);
    return children;
};

const recursiveHasSelectedChildren = (
    item: FileSystemType,
    selectedFiles: PublicLibraryFile[]
) => {
    const allChildren = recursiveGetChildren(item);
    const withoutFolders = allChildren.filter(
        (select) => select.status !== 'folder'
    );

    return withoutFolders.some((select) =>
        selectedFiles.find((sel) => sel.id === select.id)
    );
};

export {
    groupByParent,
    searchFiles,
    splitFileName,
    recursiveGetChildren,
    recursiveHasSelectedChildren,
};
