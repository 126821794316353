import {
    AnalyzerResponse,
    MissionPublicType,
    PublicConversation,
} from '@/types/requests';
import { callApi } from '../callApi';
import { PostMessageRequest } from '@/types/missions';

const apiAnalyzer = {
    getSidebarConversations: async () => {
        return callApi(`/api/analyzer/sidebar`, {
            method: 'GET',
        })
            .then((res) => {
                return res.json() as unknown as {
                    conversations: PublicConversation[];
                };
            })
            .catch((err) => {
                return null;
            });
    },
    getConversations: async ({ offset }: { offset: number }) => {
        return callApi(`/api/conversations`, {
            method: 'POST',
            body: JSON.stringify({ offset }),
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((res) => {
                return res.json() as unknown as {
                    nextOffset: number;
                    conversations: PublicConversation[];
                };
            })
            .catch((err) => {
                return null;
            });
    },
    async getConversation(id: string) {
        return callApi(`/api/conversations/${id}`, {
            method: 'GET',
        }).then((res) => {
            if (res.ok) return res.json();
            else throw new Error(res.statusText);
        });
    },
    searchConversations: async ({
        searchInput,
        offset,
    }: {
        searchInput: string;
        offset: number;
    }) => {
        return callApi(`/api/conversations/search`, {
            method: 'POST',
            body: JSON.stringify({ searchInput, offset }),
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((res) => {
                return res.json() as unknown as {
                    nextOffset: number;
                    conversations: PublicConversation[];
                };
            })
            .catch((err) => {
                return null;
            });
    },
    getMissionTracker: async () => {
        const fetched = await callApi(`/api/missions`, {
            method: 'GET',
        })
            .then((res) => {
                if (res.status !== 200) {
                    return [];
                }
                return res.json() as unknown as MissionPublicType[];
            })
            .catch((err) => {
                return [];
            });
        return fetched;
    },
    setMissionsSeen: async ({ message_ids }: { message_ids: string[] }) => {
        return callApi(`/api/missions/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ message_ids }),
        }).then((res) => {
            if (!res.ok) throw new Error(res.statusText);
        });
    },
    postMessage: async (
        params: PostMessageRequest
    ): Promise<AnalyzerResponse> => {
        return callApi(`/api/analyzer`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(params),
        }).then((res) => {
            if (res.ok) return res.json() as unknown as AnalyzerResponse;
            else throw new Error(res.statusText);
        });
    },
    confirmRoutingChoice: async (params: {
        status: 'routing_summarization';
        messageId: string;
        conversationId: string;
    }): Promise<void> => {
        return callApi(`/api/analyzer/routing/confirm`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(params),
        }).then((res) => {
            if (!res.ok) throw new Error(res.statusText);
        });
    },
    dismissRoutingChoice: async (messageId: string): Promise<void> => {
        return callApi(`/api/analyzer/routing/dismiss/${messageId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((res) => {
            if (!res.ok) throw new Error(res.statusText);
        });
    },
    onErrorRetry: async (message_id: string) => {
        return callApi(`/api/analyzer/retry/${message_id}/error`, {
            method: 'GET',
        }).then((res) => {
            if (!res.ok) throw new Error(res.statusText);
        });
    },
    retryMessage: async (message_id: string) => {
        return callApi(`/api/analyzer/retry/${message_id}/improve`, {
            method: 'GET',
        }).then((res) => {
            if (!res.ok) throw new Error(res.statusText);
        });
    },
    interruptMessage: async (message_id: string) => {
        return callApi(`/api/analyzer/interrupt/${message_id}`, {
            method: 'GET',
        }).then((res) => {
            if (!res.ok) throw new Error(res.statusText);
        });
    },
    renameConversation: async ({
        conversationId,
        name,
    }: {
        conversationId: string;
        name: string;
    }) => {
        return callApi(`/api/conversations/rename/${conversationId}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ name }),
        }).then((res) => {
            if (!res.ok) throw new Error(res.statusText);
        });
    },
};

export default apiAnalyzer;
