'use client';
import React from 'react';
import { JiminiButtonType } from '@/types/general';
import JiminiButton from '@/components/common_V2/JiminiButton';

const IconButton = ({
    icon,
    ...props
}: { icon: React.ReactNode } & Partial<JiminiButtonType>) => {
    return (
        <JiminiButton
            variant='text'
            className='min-w-[38px] rounded-full p-0.5'
            iconLeft={icon}
            {...props}
        />
    );
};

export default IconButton;
