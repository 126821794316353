import { DocumentViewerContext } from '@/components/Providers/DocumentViewerProvider';
import { typeDocumentToMime } from '@/helpers/library/uploadFile';
import apiDocuments from '@/requests/apis/documents';
import { Paragraph, UseCaseType } from '@/types/analyzer';
import { useCallback, useContext } from 'react';

const postProcessParagraphs = (paragraphs: Paragraph[] | Paragraph[][]) => {
    // sort paragraphs by page number
    return Object.values(paragraphs)[0]?.page_num
        ? [Object.values(paragraphs)]
        : Object.values(paragraphs)?.sort((a, b) => {
              const aToArray = Object.values(a) as Paragraph[];
              const bToArray = Object.values(b) as Paragraph[];

              const aPage =
                  'page_num' in a
                      ? a.page_num
                      : aToArray.length > 0
                        ? (aToArray[0] as Paragraph).page_num
                        : 0;
              const bPage =
                  'page_num' in b
                      ? b.page_num
                      : bToArray.length > 0
                        ? (bToArray[0] as Paragraph).page_num
                        : 0;

              return aPage - bPage;
          });
};

const useDocument = (typeQuestion?: UseCaseType) => {
    const {
        setDocumentDisplayed,
        setHighlightedBbox,
        setCurrentBbox,
        setSidePdfLoadingError,
        setMatrixSingleAnswerData,
    } = useContext(DocumentViewerContext);

    const getDocumentById = (id: string, contentType: string) => {
        return apiDocuments.getSignedDocument({
            id,
            contentType: typeDocumentToMime[contentType],
        });
    };
    const openDocumentFromSignedUrl = (
        url: string,
        name: string,
        contentType: string
    ) => {
        setDocumentDisplayed({
            url,
            name,
            type: contentType,
            display_type: 'reading',
        });
    };

    const openDocument = useCallback(
        async (
            id: string,
            name: string,
            contentType: string,
            paragraphs?: Paragraph[] | Paragraph[][],
            paragraph?: {
                type: 'text' | string;
                bbox: number[];
                page_num: number;
                id: number;
                content: string;
            },
            noMatrixAnswerReset?: boolean
        ) => {
            if (!noMatrixAnswerReset) {
                setMatrixSingleAnswerData(null);
            }
            const signedUrl = await getDocumentById(id, contentType);

            if (!signedUrl?.url) {
                setSidePdfLoadingError('Error');
                return;
            }
            if (paragraph) {
                // @TODO unused for now, once we have decided for the sources below the QDS, refacto to integrate "next/prev source" buttons
            } else if (paragraphs) {
                if (
                    ['summarization', 'search_adb'].includes(typeQuestion ?? '')
                ) {
                    setCurrentBbox(null);
                    setDocumentDisplayed({
                        url: signedUrl.url,
                        name,
                        type: contentType,
                        display_type: typeQuestion as UseCaseType,
                    });
                    setHighlightedBbox(Object.values(paragraphs));
                } else {
                    const newHighlightedBbox =
                        postProcessParagraphs(paragraphs);

                    if (
                        newHighlightedBbox?.length &&
                        newHighlightedBbox[0]?.length
                    ) {
                        setHighlightedBbox(newHighlightedBbox[0]);
                    } else {
                        setHighlightedBbox(newHighlightedBbox);
                    }

                    setDocumentDisplayed({
                        url: signedUrl.url,
                        name,
                        type: contentType,
                        display_type: 'factual',
                    });
                    setCurrentBbox(1);
                }
            } else {
                setDocumentDisplayed({
                    url: signedUrl.url,
                    name,
                    type: contentType,
                    display_type: 'reading',
                });
            }
        },
        [typeQuestion]
    );
    return { openDocument, openDocumentFromSignedUrl };
};

export default useDocument;
