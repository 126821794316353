import * as React from 'react';

const SearchADBIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width={24}
        height={24}
        viewBox='0 0 24 24'
        fill='none'
        {...props}
    >
        <path
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeOpacity={1}
            strokeWidth={2}
            d='M4 6c0 1.657 3.582 3 8 3s8-1.343 8-3M4 6c0-1.657 3.582-3 8-3s8 1.343 8 3M4 6v6m16-6v5.5M4 12c0 1.657 3.582 3 8 3m-8-3v6c0 1.657 3.582 3 8 3m8.2-.8L22 22m-7-4a3 3 0 1 0 6 0 3 3 0 0 0-6 0Z'
        />
    </svg>
);
export default SearchADBIcon;
