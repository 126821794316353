'use client';
import React, { DetailedHTMLProps, HTMLAttributes } from 'react';

const ThreadsPillContainer = ({
    ...props
}: Partial<
    DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>
>) => {
    return (
        <div
            className='flex max-h-[24px] min-h-[24px] w-fit min-w-fit cursor-pointer items-center whitespace-nowrap rounded border border-alphaGray4 p-2 no-underline hover:bg-materialLightblue'
            {...props}
        />
    );
};

export default ThreadsPillContainer;
