import * as React from 'react';

const TimelineIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width={24}
        height={24}
        fill='none'
        viewBox='0 0 24 24'
        {...props}
    >
        <path
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth={2}
            d='M10 20a2 2 0 0 0 4 0m-4 0a2 2 0 0 1 4 0m-4 0H4m10 0h6M9 6h6M9 9h3m0 6-2-2H7a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-3l-2 2Z'
        />
    </svg>
);
export default TimelineIcon;
