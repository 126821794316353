'use client';

import PDFIcon from '@/components/Icons/PDFIcon';
import DOCIcon from '@/components/Icons/DOCIcon';
import React from 'react';
import { splitFileName } from '@/helpers/filePicker';

const DocumentNameShortened = ({
    type,
    openDocument,
    name,
    className,
    iconContainerClassname,
    iconClassname,
    extensionColorClassName,
    textClassName,
    maxWidthClassName,
    iconProps = {},
    suffix,
}: {
    type: string;
    openDocument?: () => void;
    name: string;
    className?: string;
    iconContainerClassname?: string;
    iconClassname?: string;
    extensionColorClassName?: string;
    textClassName?: string;
    maxWidthClassName?: string;
    iconProps?: React.SVGProps<SVGSVGElement>;
    suffix?: React.ReactNode;
}) => {
    const splittedFileName = splitFileName(name);
    return (
        <div className={`flex flex-row ${className || 'px-4'} text-left`}>
            <div
                className={`${iconContainerClassname || 'mr-2'} flex items-center justify-center rounded-lg text-white`}
            >
                {type === 'pdf' ? (
                    <PDFIcon
                        className={iconClassname || `stroke-black`}
                        {...iconProps}
                    />
                ) : (
                    <DOCIcon
                        className={iconClassname || `stroke-black`}
                        {...iconProps}
                    />
                )}
            </div>

            <div
                className={`flex flex-row items-center justify-center ${
                    !!openDocument ? 'cursor-pointer' : ''
                }`}
                onClick={() => {
                    if (openDocument) {
                        openDocument();
                    }
                }}
            >
                <span
                    className={`${maxWidthClassName || 'max-w-[106px]'} overflow-hidden text-ellipsis whitespace-nowrap text-left ${textClassName || 'font-gilroyTitle text-base text-solidGray12'} `}
                >
                    {splittedFileName.start}
                </span>
                <span
                    className={`text-left ${textClassName || 'font-gilroyTitle text-base text-solidGray12'} `}
                >
                    {splittedFileName.end?.split('.')?.[0] ?? ''}
                </span>
                <span
                    className={`whitespace-nowrap text-left ${textClassName || 'font-gilroyTitle text-base'} ${extensionColorClassName || 'text-solidGray9'}`}
                >
                    .{type}
                </span>
                {suffix}
            </div>
        </div>
    );
};

export default DocumentNameShortened;
