import * as React from 'react';

const ClausesIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width={24}
        height={24}
        fill='none'
        viewBox='0 0 24 24'
        {...props}
    >
        <path
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth={2}
            d='m12 12 8-4.5M12 12v9m0-9L4 7.5m8 4.5 8 4.5M12 12V3m0 9-8 4.5'
        />
    </svg>
);
export default ClausesIcon;
