'use client';
import React, { useMemo } from 'react';
import Tooltip from '@/components/common/Tooltip';
import {
    ThreadsFileShortenedNameButton,
    ThreadsFirstFileShortenedNameButton,
} from '@/components/Conversations/ThreadsFileShortenedName';
import useDocument from '@/hooks/useDocument';
import { PublicLibraryFile } from '@/types/requests';

const ThreadConversationFilesTooltip = ({
    files,
}: {
    files: PublicLibraryFile[];
}) => {
    // list of unique files used in the conversation, for the conversation page

    const { openDocument } = useDocument();
    const openThreadDocument = (file: PublicLibraryFile) => {
        openDocument(file?.id, file?.name, file?.type);
    };

    const tooltipTitle = useMemo(() => {
        const computedTitle = files
            .slice(1, files.length)
            ?.map((file, index) => (
                <ThreadsFileShortenedNameButton
                    file={file}
                    key={index}
                    index={index}
                    openDocument={openThreadDocument}
                />
            ));
        return computedTitle?.length ? computedTitle : '';
    }, []);
    return (
        <Tooltip placement='top' title={tooltipTitle}>
            {/* somehow if there is no div between tooltip and component, the tooltip
            does not appear. Even if the component has a parent div container
             Probably an MUI issue */}
            <div className='mr-2 rounded-[4px] bg-white'>
                <ThreadsFirstFileShortenedNameButton
                    files={files}
                    openDocument={openThreadDocument}
                />
            </div>
        </Tooltip>
    );
};

export default ThreadConversationFilesTooltip;
