'use client';

import React, { forwardRef } from 'react';
import Button from '@mui/material/Button';
import { JiminiButtonType } from '@/types/general';

const JiminiButton = forwardRef<HTMLButtonElement, JiminiButtonType>(
    (
        {
            className,
            textClassName,
            heightTextClassName,
            sizeClassName,
            variant = 'contained',
            // rippleColor, soon
            backgroundColor,
            borderColor,
            textColor,
            textSize,
            text,
            iconLeft,
            iconRight,
            shrink,
            ...props
        },
        ref
    ) => {
        if (variant === 'contained') {
            // no universal rule for the padding was found because it depends on the icons
            // minimum 12px horizontally, customise through iconLeft/iconRight's respective classnames
            const hasColorInversion =
                (props?.disabled && !backgroundColor) ||
                (props?.disabled && backgroundColor?.includes('solidBlue12'));
            const postProcessedBackground = hasColorInversion
                ? 'bg-alphaBlue2'
                : backgroundColor || 'bg-solidBlue12';
            const postProcessedTextColor = hasColorInversion
                ? 'text-alphaGray8'
                : textColor || 'text-white';
            return (
                <Button
                    ref={ref}
                    {...props}
                    variant={variant}
                    className={`${
                        className || 'px-3'
                    } ${postProcessedBackground} ${
                        props?.disabled ? 'opacity-100' : 'opacity-100'
                    } ${borderColor || ''} ${
                        sizeClassName || 'h-[40px] min-h-[40px]'
                    } transition-200 rounded-[6px] shadow-none`}
                >
                    {iconLeft}
                    {text ? (
                        <span
                            className={`${postProcessedTextColor} ${
                                textSize || 'text-base leading-[16px]'
                            } ${textClassName || 'font-gilroySemiBold'} ${
                                heightTextClassName || 'max-h-[14px]'
                            } transition-200 normal-case`}
                        >
                            {text}
                        </span>
                    ) : null}
                    {iconRight}
                </Button>
            );
        } else if (variant === 'outlined') {
            return (
                <Button
                    {...props}
                    variant={variant}
                    className={`${className} ${
                        borderColor || 'border-solidBlue12'
                    } h-[40px] rounded-[6px]`}
                >
                    {iconLeft}
                    {text ? (
                        <span
                            className={`${textColor || 'text-solidBlue12'} ${
                                textSize || 'text-base leading-[16px]'
                            } ${textClassName || 'font-gilroySemiBold'} ${heightTextClassName || 'max-h-[14px]'} normal-case`}
                        >
                            {text}
                        </span>
                    ) : null}
                    {iconRight}
                </Button>
            );
        } else if (variant === 'text') {
            return (
                <Button
                    {...props}
                    variant={variant}
                    className={`${className} h-[40px] text-solidBlue12`}
                >
                    {iconLeft}
                    {text ? (
                        <span
                            className={`${
                                textColor || 'text-solidBlue12'
                            } max-h-[14px] ${
                                textSize || 'text-base leading-[16px]'
                            } ${textClassName || 'font-gilroySemiBold'} origin-left normal-case transition-all duration-200 ease-in-out ${
                                shrink
                                    ? 'scale-x-0 opacity-0'
                                    : 'scale-x-100 opacity-100'
                            }`}
                        >
                            {text}
                        </span>
                    ) : null}
                    {iconRight}
                </Button>
            );
        }
    }
);

JiminiButton.displayName = 'JiminiButton';

export default JiminiButton;
